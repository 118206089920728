<template>
    <div>
        <LiefengModal title="设置权限" :fullscreen="true" :value="sendGroupStatus" @input="sendGroupStatusFn">
            <template v-slot:contentarea>
                <div>
                    <Form :label-width="120">
                        <FormItem>
                            <span slot="label">绑定角色:</span>
                            <CheckboxGroup v-model="saveRow.roleId">
                                <Checkbox :label="item.id" v-for="(item, index) in roleList" :key="index">{{ item.name }}</Checkbox>
                            </CheckboxGroup>
                        </FormItem>
                        <FormItem>
                            <span slot="label" class="validate">系统管理员:</span>
                            <RadioGroup v-model="saveRow.adminType">
                                <Radio label="200">是</Radio>
                                <Radio label="1">否</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem>
                            <span slot="label">流程权限:</span>
                            <RadioGroup v-model="saveRow.flowCode">
                                <Radio label="1001">拟制</Radio>
                                <Radio label="1002">审核</Radio>
                                <Radio label="1003">发布</Radio>
                                <Radio label="1004">查看</Radio>
                            </RadioGroup>
                        </FormItem>
                    </Form>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 10px" @click="sendGroupStatusFn(false)">取消</Button>
                <Button type="primary" @click="saveSendGroupNotice">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import proxy from "@/api/proxy.js"
export default {
    components: {
        LiefengModal,
    },
    data() {
        return {
            sendGroupStatus: true,
            roleList: [],
            saveRow: {
                staffId: "",
                orgCode: "",
                flowCode: "",
                roleId: [],
                adminType: "",
            },
            staffId: "",
            orgCode: "",
        }
    },
    async created() {
        this.staffId = this.$route.query.staffId || parent.vue.loginInfo.userinfo.id
        this.orgCode = this.$route.query.orgCode || window.sessionStorage.getItem("orgCode")
        await this.getCutRole()
        await this.getRoleList()
    },
    methods: {
        sendGroupStatusFn(status) {
            this.sendGroupStatus = status
            parent.layer.closeAll()
        },
        saveSendGroupNotice() {
            if (!this.saveRow.adminType || this.saveRow.adminType == "") {
                this.$Message.warning({
                    content: "请选择是否系统管理员",
                    background: true,
                })
                return
            }
            this.$.ajax({
                url: proxy["/gateway"].target + "/apps/oldpeople/api/pc/staff/saveUserHasRole",
                type: "POST",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    ...this.saveRow,
                    roleId: this.saveRow.roleId && this.saveRow.roleId.length ? this.saveRow.roleId.join(",") : "",
                    staffId: this.staffId,
                    orgCode: this.orgCode,
                    adminType: this.saveRow.adminType == "1" ? "" : this.saveRow.adminType,
                },
                success: res => {
                    if (res && res.code && res.code == 200) {
                        this.$Message.success({
                            content: "设置成功",
                            background: true,
                        })
                        this.sendGroupStatusFn(false)
                        return
                    }
                },
            })
        },
        async getRoleList() {
            await this.$.ajax({
                url: proxy["/gateway"].target + "/apps/oldpeople/api/pc/staff/selectOrgHasRole",
                type: "GET",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    orgCode: this.orgCode,
                },
                success: res => {
                    if (res && res.dataList) {
                        this.roleList = res.dataList
                    }
                },
            })
        },
        async getCutRole() {
            await this.$.ajax({
                url: proxy["/gateway"].target + "/apps/oldpeople/api/pc/staff/selectUserHasRole",
                type: "GET",
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                data: {
                    orgCode: this.orgCode,
                    staffId: this.staffId,
                },
                success: res => {
                    if (res && res.data) {
                        this.saveRow.flowCode = res.data.flowCode && res.data.flowCode.length ? res.data.flowCode.join(",") : ""
                        this.saveRow.roleId = res.data.roleIdList
                        this.saveRow.adminType = res.data.adminType || '1'
                    } else {
                        this.saveRow = {
                            staffId: "",
                            orgCode: "",
                            flowCode: "",
                            roleId: [],
                        }
                    }
                },
            })
        },
    },
}
</script>

<style scoped lang="less">
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
</style>